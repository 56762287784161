@use "@angular/material" as mat;
@import "theme";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@mixin psa-custom-component($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  @import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500");
  @import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light");

  body {
    -ms-overflow-style: none;
    background-color: $grey-light;
    font-family: "Roboto", sans-serif;
  }
  .loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: mat.get-color-from-palette($primary, 950);
  }

  .background-primary {
    background-color: mat.get-color-from-palette($primary, 950);
  }

  .mat-sort-header-button {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    background-color: transparent;
  }
  .mat-sort-header-arrow {
    display: none !important;
  }
  .white-input {
    mat-form-field {
      .mat-form-field-wrapper {
        width: 100%;
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-input-element {
              color: $white;
              caret-color: $white;
            }
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                color: $white;
              }
            }
          }
        }
        .mat-form-field-underline {
          background-color: $white;
        }
      }
    }

    mat-form-field.mat-focused {
      .mat-form-field-wrapper {
        width: 100%;
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-input-element {
              color: mat.get-color-from-palette($accent, 950);
            }
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                color: mat.get-color-from-palette($accent, 950);
              }
            }
          }
        }
        .mat-form-field-underline {
          .mat-form-field-ripple {
            color: mat.get-color-from-palette($accent, 950);
          }
        }
      }
    }

    button {
      background-color: $white;
      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.24),
        0 0 2px 0 rgba(0, 0, 0, 0.12);
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.06)
      );
    }
  }

  .logos {
    display: flex;
    padding: 0rem var(--spacing-margin-padding, 2rem) 0rem 1.25rem;
    align-items: center;
    gap: var(--spacing-margin-padding, 2rem);
    align-self: stretch;
    position: absolute;

    img.stellantis {
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;

      width: 11.10525rem;
      height: 2.05181rem;

      padding-top: 0.5rem;
    }

    p.track-et-trace {
      z-index: 100;
      display: flex;
      padding: 0.9375rem 0rem;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;

      color: #fff;
      text-align: center;
      font-family: IBM Plex Sans;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.8rem; /* 100% */

      margin-left: 15rem;
    }
  }

  mat-checkbox.psa-custom {
    .mat-checkbox-checked {
      background-color: mat.get-color-from-palette($primary, 950);
    }
  }

  mat-toolbar.psa-custom {
    background-color: mat.get-color-from-palette($primary, 950);
  }

  mat-toolbar.psa-custom.iemargin .mat-tab-body-wrapper {
    margin-left: -200px;
  }
  mat-toolbar.psa-custom.iemargin mat-tab-group {
    width: calc(100% - 200px) !important;
  }

  .mat-tab-body-wrapper {
    display: flex;
    z-index: 10;
    position: fixed;
    position: -webkit-sticky; /* For macOS/iOS Safari */
    top: 0; /* Sets the sticky toolbar to be on top */
    background-color: $white;
    width: 100%;
    height: calc(100vh - 48px); // 100% of height screen - mat tab header height
  }

  .app-toolbar {
    display: flex;
    position: sticky;
    position: -webkit-sticky; /* For macOS/iOS Safari */
    top: 0; /* Sets the sticky toolbar to be on top */
  }

  .logo {
    z-index: 100;
    position: absolute;
  }

  .main-tab-group {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    mat-tab-header {
      background-color: $black;
      position: relative;
      width: 100%;
      display: flex;
      overflow: auto;
      flex-shrink: 20;
      align-self: center;
      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent, 950) !important;
        height: 5px;
      }

      .close-nav {
        height: 2.5vh;
        width: 2vw;
        border-color: $white !important;
      }
    }
  }
}
